import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMaterialByCourse, getQuizByCourseId } from '../../services/api'; // Pastikan untuk mengganti dengan path yang benar

// Ikon Hamburger
const HamburgerIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-menu">
    <line x1="4" x2="20" y1="12" y2="12" />
    <line x1="4" x2="20" y1="6" y2="6" />
    <line x1="4" x2="20" y1="18" y2="18" />
  </svg>
);

// Ikon Close
const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x">
    <path d="M18 6 6 18" />
    <path d="m6 6 12 12" />
  </svg>
);

// Ikon centang
const CheckIcon = ({ completed }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke={completed ? '#24cc6d' : '#d3d3d3'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-check">
    <circle cx="12" cy="12" r="10" />
    <path d="m9 12 2 2 4-4" />
  </svg>
);

const Sidebar = ({ isOpen, toggleSidebar, courseId }) => {
  const [chapters, setChapters] = useState([]);
  const [quizzes, setQuizzes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (courseId) {
      const fetchChaptersAndQuizzes = async () => {
        try {
          const materials = await getMaterialByCourse(courseId);
          setChapters(materials);

          const quizzesData = await getQuizByCourseId(courseId);
          setQuizzes(quizzesData);
        } catch (error) {
          console.error("Error fetching chapters and quizzes:", error);
        }
      };

      fetchChaptersAndQuizzes();
    }
  }, [courseId]);

  const navigateToMaterial = (materialId) => {
    navigate(`/courses/${courseId}/materials/${materialId}`, {
      state: { courseId: courseId }
    });
  };

  const navigateToQuiz = (quizId) => {
    navigate(`/courses/${courseId}/quizzes/${quizId}`, {
      state: { courseId: courseId }
    });
  };

  return (
    <div className="relative">
      {isOpen ? (
        <div className="w-full md:w-64 bg-white border rounded-lg border-solid border-gray-200 p-6">
          <div className="flex items-center justify-between mb-4">
            <span className="text-lg font-semibold">Daftar Isi</span>
            <div onClick={toggleSidebar} className="cursor-pointer">
              <CloseIcon />
            </div>
          </div>
          <ul>
            {chapters.map((chapter, index) => (
              <li key={index} className="mb-4 flex items-center cursor-pointer" onClick={() => navigateToMaterial(chapter.id)}>
                <div className="mr-4">
                  <CheckIcon completed={false} />
                </div>
                <span className='text-[14px]'>{chapter.title}</span>
              </li>
            ))}
            {quizzes.map((quiz, index) => (
              <li key={index} className="mb-4 flex items-center cursor-pointer" onClick={() => navigateToQuiz(quiz.id)}>
                <div className="mr-4">
                  <CheckIcon completed={false} />
                </div>
                <span className="flex-grow text-[14px]">Quiz: {quiz.title}</span>
                
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="p-6 border border-solid border-gray-200 rounded-lg cursor-pointer" onClick={toggleSidebar}>
          <HamburgerIcon />
        </div>
      )}
    </div>
  );
};


export default Sidebar;