import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../components/courses/sidebar';
import QuizResult from './quizResult';
import Navbar from '../components/courses/navbar';
import Footer from '../components/beranda/footer';
import { getQuizById, getQuestionByQuizId } from '../services/api'; // Import fungsi tambahan

// Komponen SVG Ikon
const MoveRightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-move-right">
    <path d="M18 8L22 12L18 16"/>
    <path d="M2 12H22"/>
  </svg>
);

const ChevronRightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-right">
    <path d="m9 18 6-6-6-6"/>
  </svg>
);

const ChevronLeftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-left">
    <path d="m15 18-6-6 6-6"/>
  </svg>
);

const Quizes = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [score, setScore] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questions, setQuestions] = useState([]); // State untuk menyimpan pertanyaan
  const [courseId, setCourseId] = useState(null); // State untuk menyimpan courseId
  const navigate = useNavigate();
  const { quizId } = useParams(); // Ambil quizId dari parameter URL
  useEffect(() => {
    const fetchQuizData = async () => {
      try {
        const quizData = await getQuizById(quizId);
        // Ekstrak courseId dari path referensi
        const extractedCourseId = quizData.course_id.split('/').pop();
        setCourseId(extractedCourseId);
        const questionsData = await getQuestionByQuizId(quizId);
        setQuestions(questionsData);
      } catch (error) {
        console.error('Error fetching quiz data', error);
      }
    };
    
    if (quizId) {
      fetchQuizData();
    }
  }, [quizId]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAnswerClick = (answer) => {
    setSelectedAnswers((prev) => {
      const newAnswers = [...prev];
      newAnswers[currentQuestion] = answer;
      return newAnswers;
    });
  };

  const handleNextClick = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setIsSubmitted(true);
      calculateScore();
    }
  };

  const handlePrevClick = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const calculateScore = () => {
    const correctAnswers = selectedAnswers.filter(
      (answer, index) => answer === questions[index].answer
    ).length;
    const totalScore = (correctAnswers / questions.length) * 100;
    setScore(totalScore);
  };

  const handleButtonClick = () => {
    navigate('/courses');
  };

  if (questions.length === 0) {
    return <div>Loading...</div>; // Tampilkan loading state jika data belum tersedia
  }

  return (
    <div className="min-h-screen">
      {/* Bagian Atas */}
      <div className="sticky top-0 z-10 bg-white">
        <Navbar />
      </div>
      <div className="flex justify-between items-center p-4 rounded-lg">
        {/* Bagian Kiri Atas */}
        <div className="text-sm">
          {/* Konten Kiri Atas jika ada */}
        </div>
        {/* Bagian Kanan Atas */}
        <div>
          <button 
            className="bg-gray-200 text-gray-600 flex items-center px-6 py-1 rounded" 
            onClick={handleButtonClick}
          >
            <MoveRightIcon style={{ fontSize: '10px' }} />
          </button>
        </div>
      </div>

      {/* Bagian Bawah */}
      <div className="flex flex-col md:flex-row mt-4">
        {/* Sidebar (Kiri) */}
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} courseId={courseId} />

        {/* Konten (Kanan) */}
        <div className="flex-1 bg-white border border-solid border-d8d8d8 mt-4 md:mt-0 md:ml-4 rounded-lg p-6 transition-all duration-10000 ease-out lg:ml-4" style={{ padding: '24px 21px' }}>
          {score === null ? (
            <>
              <h2 className="text-lg font-semibold mb-4 text-[14px]">Soal {currentQuestion + 1}</h2>
              <div className="mb-4 text-[14px]">{questions[currentQuestion].question}</div>
              <div>
                {questions[currentQuestion].opt.map((option, index) => (
                  <div
                    key={index}
                    className={`p-2 mb-2 border rounded cursor-pointer ${
                      selectedAnswers[currentQuestion] === option
                        ? "bg-gray-200"
                        : "bg-white"
                    } text-[14px]`}
                    onClick={() => handleAnswerClick(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
              <div className="flex justify-end mt-4 gap-3">
                {currentQuestion > 0 && (
                  <button
                    className="bg-gray-200 text-gray-600 flex items-center px-6 py-1 rounded"
                    onClick={handlePrevClick}
                  >
                    <ChevronLeftIcon style={{ fontSize: '10px' }} />
                  </button>
                )}
                <button
                  className="bg-gray-200 text-gray-600 flex items-center px-6 py-1 rounded"
                  onClick={handleNextClick}
                >
                  {currentQuestion < questions.length - 1 ? (
                    <ChevronRightIcon style={{ fontSize: '10px' }} />
                  ) : (
                    <span className="text-sm">Submit</span>
                  )}
                </button>
              </div>
            </>
          ) : (
            <QuizResult
              score={score}
              selectedAnswers={selectedAnswers}
              questions={questions}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Quizes;
