import React from 'react';
import { images } from '../constants';
import Navbar from '../components/courses/navbar';
import Footer from '../components/beranda/footer';

const PaketSoal = () => {
  return (
    <>
    <div className="sticky top-0 z-10 bg-white">
     <Navbar /></div>
      <div className="flex flex-col justify-center items-center mt-20">
        <img src={images.EmptyState} alt="Empty State" />
        <h1 className="text-lg font-semibold">Coming Soon!</h1>
        <div className="text-md  cursor-pointer">
          Sabar ya, Paket Soal akan segera hadir
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaketSoal;
