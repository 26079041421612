import React from 'react';

const QuizResult = ({ score, selectedAnswers, questions }) => {
  const correctAnswersCount = selectedAnswers.filter(
    (answer, index) => answer === questions[index].answer
  ).length;

  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold">Skor Anda</h2>
      <p className="text-[14px] mt-2">Anda menjawab benar {correctAnswersCount} dari {questions.length} soal</p>
      <p className="text-4xl mt-4">{score}%</p>

      <div className="mt-8">
        {questions.map((question, index) => (
          <div key={index} className="mb-6">
            <div className="text-left font-semibold text-[14px] mb-2">{index + 1}. {question.question}</div>
            {question.opt.map((option, optIndex) => {
              const isSelected = selectedAnswers[index] === option;
              const isCorrect = option === question.answer;
              const isAnswer = isSelected || isCorrect;
              
              return (
                <div
                  key={optIndex}
                  className={`flex text-left items-center text-[14px] p-2 mb-2 border rounded ${isAnswer ? (isCorrect ? "bg-[#D3FFE6] border border-[#24cc6d]" : "bg-[#FFD7D7] border border-edured") : "bg-white border border-d8d8d8"}`}
                >
                  <span className="flex-1">{option}</span>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuizResult;
