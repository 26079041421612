import React from "react";

import { images } from "../../constants";

const EmptyState = ({ message, subMessage }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 p-8">
      <img src={images.EmptyState} alt="Empty State" />
      <h1 className="text-lg font-semibold">{message}</h1>
      <a href="/" className="text-lg font-bold cursor-pointer">
        {subMessage}
      </a>
    </div>
  );
};

export default EmptyState;