import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { images } from "../../constants/";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleSignUpClick = () => {
    navigate('/signup'); // Navigate to /signup
  };

  const handleSignInClick = () => {
    navigate('/signin'); // Navigate to /signin
  };

  const handleLogoClick = () => {
    navigate('/'); // Navigate to home page
  };

  return (
    <section>
      <header className=" sticky top-0 z-10 bg-white header-container m-auto py-5 flex justify-between items-center">
        <div className="header-image-container h-9 w-30">
          <img 
            className="h-full cursor-pointer" 
            src={images.Logo} 
            alt="Logo" 
            onClick={handleLogoClick} // Add onClick handler
          />
        </div>

        {/* Menu Hamburger untuk Mobile */}
        <div className="lg:hidden">
          <button onClick={toggleMenu} className="mobile-menu-button">
            {showMenu ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-x"
                style={{ transition: "transform 0.3s ease-in-out" }}
              >
                <path d="M18 6 6 18"/>
                <path d="m6 6 12 12"/>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-menu"
                style={{ transition: "transform 0.3s ease-in-out" }}
              >
                <line x1="4" x2="20" y1="12" y2="12"/>
                <line x1="4" x2="20" y1="6" y2="6"/>
                <line x1="4" x2="20" y1="18" y2="18"/>
              </svg>
            )}
          </button>
        </div>

        {/* Menu Navigasi untuk Desktop */}
        <div className="sticky top-0 z-10 bg-white hidden lg:flex gap-x-8 items-center">
          <div className="flex gap-x-1.5">
            <button className="bg-edured px-4 py-1.5 text-white" onClick={handleSignUpClick}>Sign Up</button>
            <button className="bg-white border border-edured px-4 py-1.5 text-edured" onClick={handleSignInClick}>Sign In</button>
          </div>
        </div>
      </header>

      {/* Menu Navigasi untuk Mobile */}
      <div className={`lg:hidden ${showMenu ? 'block' : 'hidden'}`}>
        <div className="flex flex-col gap-y-4 py-4 px-4 border-t border-gray-200">
          <button className="bg-edured px-4 py-1.5 text-white" onClick={handleSignUpClick}>Sign Up</button>
          <button className="bg-white border border-edured px-4 py-1.5 text-edured" onClick={handleSignInClick}>Sign In</button>
        </div>
      </div>
    </section>
  );
};

export default Header;
