import React, { useState } from 'react';
import MainLayout from '../components/beranda/mainLayout';
import Modal from '../components/Modal';
import Header from '../components/beranda/header';
import Footer from '../components/beranda/footer';
import { images } from "../constants";

// Hero Component
const Hero = () => {
  return (
    <section id="hero" className="flex flex-col my-20 w-full h-auto">
      <div className=" w-full">
        <img src={images.Hero} alt="Hero" className="w-full h-full object-cover" />
      </div>
    </section>
  );
};

// Introduction Component
const Introduction = () => {
  return (
    <section id="introduction" className="w-full flex flex-col my-20 space-y-4">
      <span className="text-black text-2xl font-semibold">Hello Kohai!</span>
      <span className="text-edured text-3xl font-medium">Selamat datang di EduSensei!</span>
      <span>
        EduSensei merupakan platform belajar online yang hadir dengan
        visualisasi Virtual Youtuber. Dengan belajar di EduSensei kamu bisa
        merasakan pengalaman yang menyenangkan dalam belajar. Dibimbing oleh
        mahasiswa dari universitas terbaik dengan materi yang komprehensif, dan
        visualisasi yang menarik.
      </span>
    </section>
  );
};

// Character Component
const Character = () => {
  return (
    <section id="character" className="w-full flex flex-col my-20 space-y-4">
      <div className="w-full flex flex-col space-y-4">
        <span className="text-black text-2xl font-semibold">Mari kenalan dengan</span>
        <span className="text-edured text-3xl font-medium">Sensei di EduSensei</span>
      </div>
      <div className="flex flex-col md:flex-row justify-center w-full">
        <div className="flex justify-center sm:w-full">
          <img src={images.Character} alt="Char" className="w-5/6 md:w-full h-full object-cover" />
        </div>
        <div className="w-full flex flex-col space-y-4 justify-center items-center md:items-start text-center sm:text-left px-10 py-5">
          <span>Sensei</span>
          <span className="text-edured text-xl md:text-2xl font-semibold">Raya Kimura</span>
          <span className="text-sm md:text-xl">“Jangan pernah berhenti belajar, karena ilmu tak pernah habis”</span>
          <span className="text-[12px] md:text-[14px]">Universitas Hasanuddin | Teknik Informatika</span>
        </div>
      </div>
    </section>
  );
};

// People Component
const People = () => {
  const names = ["Novy N.R.A.", "Nurunnisa Fathanah", "Muthia Anashya", "Rachmat", "Mohammad Aqsha", "Muh. Rhayyan"];
  const lastNames = ["Mokobombang, S.T., Ms.TM, Ph.D", "Dz. S. B.", "Salam", "Maulana", "Arianto Latif", "Zhakhi"];
  const roles = ["Dosen pembimbing", "Chief Executive Officer (CEO)", "Chief Technology Officer (CTO)", "Chief Financial Officer (CFO)", "Chief Marketing Officer (CMO)", "Chief Product Officer (CPO)"];
  const peopleImages = [images.People1, images.People2, images.People3, images.People4, images.People5, images.People6];

  return (
    <section id="people" className="w-full flex flex-col my-20 space-y-4">
      <div className="flex flex-col space-y-4">
        <span className="text-black text-2xl font-semibold">Kenalan juga bareng</span>
        <span className="text-edured text-3xl font-medium italic">People behind EduSensei</span>
      </div>
      <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-8">
        {names.map((name, index) => (
          <div key={index} className="bg-white p-3 md:p-6 flex flex-col justify-between border border-customgray gap-3 w-full mx-auto">
            <div className="flex flex-col gap-3 justify-start">
              <div className="bg-gray-400 w-full aspect-[3/4]">
                <img src={peopleImages[index]} alt={`Person ${index + 1}`} className="w-full h-full object-cover" />
              </div>
              <div className="flex flex-col items-start bg-white w-full space-y-3">
                <div className="w-full">
                  <span className="text-edured block font-medium text-[16px] md:text-xl">{name}</span>
                  <span className="text-black block text-gray-600 text-[12px] lg:text-base">{lastNames[index]}</span>
                </div>
              </div>
            </div>
            <div>
              <span className="block text-black font-semibold text-[14px] md:text-lg">{roles[index]}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const Home = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalType, setModalType] = useState("");

  const handleSignUpClick = () => {
    setModalType("signUp");
    setModalVisible(true);
  };

  const handleSignInClick = () => {
    setModalType("signIn");
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div>
      
      <div className="sticky top-0 z-1 bg-white"> {/* Tambahkan kelas sticky di sini */}
        <Header onSignUpClick={handleSignUpClick} onSignInClick={handleSignInClick} />
      </div>
      <Hero />
      <Introduction />
      <Character />
      <People />
      <Footer/>
    </div>
  );
};

export default Home;
