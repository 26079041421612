import React from "react";
import { useNavigate } from 'react-router-dom';
import { getQuizByCourseId } from "../../services/api";

const CardGrid = ({ courses, activeTab }) => {
  const navigate = useNavigate();

  const handleTitleClick = async (courseId) => {
    if (activeTab === "kuis") {
      try {
        // Ambil kuis untuk course ini
        const quizzes = await getQuizByCourseId(courseId);
        if (quizzes.length > 0) {
          // Navigasi ke kuis pertama
          const firstQuizId = quizzes[0].id;
          navigate(`/courses/${courseId}/quizzes/${firstQuizId}`);
        } else {
          console.error("No quizzes found for course:", courseId);
        }
      } catch (error) {
        console.error('Error fetching quizzes', error);
      }
    } else {
      // Navigasi ke course
      navigate(`/courses/${courseId}`);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-6">
      {courses.map((course) => (
        <div key={course.id} className="bg-white rounded-lg overflow-hidden shadow-sm h-70 p-4 border border-gray-300" onClick={() => handleTitleClick(course.id)} >
          <div>
            <img src={course.image} alt={course.title} className="w-full h-1/2 object-cover rounded-sm" />
            <div>
              <h3
                className="text-[16px] font-semibold cursor-pointer mt-2"
                onClick={() => handleTitleClick(course.id)} 
              >
                {course.title}
              </h3>
              <p className="text-[10px] sm:text-xs md:text-sm text-gray-600 mb-2">{course.sensei}</p>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="border border-gray-500 text-gray-500 text-[8px] sm:text-[10px] md:text-xs px-2 py-1 sm:px-3 sm:py-1 rounded-full">
              {course.category}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardGrid;
