// ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './contexts/authContext';

const ProtectedRoute = ({ element: Element }) => {
  const { currentUser } = useAuth();

  return currentUser ? <Element /> : <Navigate to="/signin" />;
};

export default ProtectedRoute;
