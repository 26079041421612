import React, { useState, useEffect } from "react";
import { RiPencilLine } from "react-icons/ri";
import { storage } from "../../firebase"; // Import Firebase storage
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { updateUser, getUserById } from "../../services/api"; // Import your API functions
import { getAuth } from "firebase/auth";
import {images} from "../../constants"

const FormProfil = ({ onSave }) => {
  const [imageSrc, setImageSrc] = useState("https://placehold.co/200");
  const [file, setFile] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [profileData, setProfileData] = useState({
    name: "",
    birthdate: "",
    province: "",
    city: "",
    school: "",
    dream_university: "",
    profileImage: "",
    email: ""
  });

  useEffect(() => {
    const auth = getAuth();
    setCurrentUser(auth.currentUser);
  }, []);

  useEffect(() => {
    if (currentUser) {
      const fetchData = async () => {
        try {
          const data = await getUserById(currentUser.uid);
          setProfileData(data);
  
          // Cek apakah profileImage bukan "Default"
          if (data.profileImage !== "default") {
            setImageSrc(data.profileImage);
          } else {
            setImageSrc(images.DefaultPP);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
  
      fetchData();
    }
  }, [currentUser]);

  const handleImageUpload = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Validate file type
      if (!['image/jpeg', 'image/jpg', 'image/png'].includes(selectedFile.type)) {
        alert('Please upload a valid image file (JPG, JPEG, or PNG).');
        return;
      }

      // Validate file size (1MB max)
      if (selectedFile.size > 1 * 1024 * 1024) {
        alert('File size exceeds 1MB.');
        return;
      }

      setFile(selectedFile);
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageSrc(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
  
    try {
      // Upload new profile image if available
      if (file) {
        // URL default profile image yang tidak boleh dihapus
        const defaultProfileImageUrl = "default";
        const defaultProfileImagePath = "/static/media/default-pp.84ca6d0a1187331c5c99.png";
        
        // Delete old profile image if it exists and is not the default image
        if (profileData.profileImage && profileData.profileImage !== defaultProfileImageUrl && profileData.profileImage !== defaultProfileImagePath) {
          const oldImageRef = ref(storage, profileData.profileImage);
          await deleteObject(oldImageRef);
        }
  
        // Upload new profile image
        const imageRef = ref(storage, `profileImages/${file.name}`);
        await uploadBytes(imageRef, file);
        const imageUrl = await getDownloadURL(imageRef);
        formData.append("profileImage", imageUrl);
      } else {
        formData.append("profileImage", imageSrc);
      }
  
      const data = {
        name: formData.get("name"),
        birthdate: formData.get("birthdate"),
        province: formData.get("province"),
        city: formData.get("city"),
        school: formData.get("school"),
        dream_university: formData.get("dream_university"),
        profileImage: formData.get("profileImage"),
        email: currentUser?.email
      };
  
      await updateUser(currentUser?.uid, data);
      onSave(data);
    } catch (error) {
      console.error("Error updating user:", error);
      // Handle the error appropriately
    }
  };
  

  return (
    <div className="flex flex-col w-full md:max-w-3xl max-h-full my-50">
      <section className="flex flex-col py-6 items-center gap-4 md:gap-12 md:flex-row">
        <div className="header-image-container flex items-center justify-center">
          <img src={imageSrc} alt="Profile" className="w-40 h-40 object-cover rounded-full" />
        </div>
        <label htmlFor="imageUpload" className="bg-[#D9D9D9] px-4 py-3 rounded-lg flex items-center gap-1 w-fit h-fit cursor-pointer hover:opacity-75">
          <RiPencilLine className="text-xl" />
          <p>Edit Profil</p>
          <input
            type="file"
            id="imageUpload"
            className="hidden"
            accept="image/*"
            onChange={handleImageUpload}
          />
        </label>
      </section>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Nama Lengkap:
          </label>
          <input type="text" id="name" name="name" defaultValue={profileData.name} className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300" />
        </div>

        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Email:
          </label>
          <input type="text" id="name" name="name" defaultValue={currentUser?.email} disabled readOnly className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300" />
        </div>
        
        

        <div className="mb-4">
          <label htmlFor="birthdate" className="block text-sm font-medium text-gray-700">
            Tanggal Lahir:
          </label>
          <input type="date" id="birthdate" name="birthdate" defaultValue={profileData.birthdate} className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300" />
        </div>

        <div className="mb-4">
          <label htmlFor="province" className="block text-sm font-medium text-gray-700">
            Provinsi Asal:
          </label>
          <input type="text" id="province" name="province" defaultValue={profileData.province} className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300" />
        </div>

        <div className="mb-4">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">
            Kota Asal:
          </label>
          <input type="text" id="city" name="city" defaultValue={profileData.city} className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300" />
        </div>

        <div className="mb-4">
          <label htmlFor="school" className="block text-sm font-medium text-gray-700">
            Nama Sekolah:
          </label>
          <input type="text" id="school" name="school" defaultValue={profileData.school} className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300" />
        </div>

        <div className="mb-4">
          <label htmlFor="dream_university" className="block text-sm font-medium text-gray-700">
            Universitas Impian:
          </label>
          <input
            type="text"
            id="dream_university"
            name="dream_university"
            defaultValue={profileData.dream_university}
            className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>

        <div className="flex w-full text-center">
          <button type="submit" className="w-full bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-opacity-50">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormProfil;
