import React, { useState, useEffect } from "react";
import { getUserById } from "../../services/api"; // Pastikan path sesuai dengan lokasi file api.js
import ModalProfil from "./modalProfile";
import { RiGraduationCapFill, RiPencilLine } from "react-icons/ri";
import { MdLocationOn } from "react-icons/md";
import { useAuth } from "../../contexts/authContext"; // Pastikan path sesuai dengan lokasi file authContext
import {images} from "../../constants"
const Header = () => {
  const { currentUser, logout } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [profileData, setProfileData] = useState({
    uid: "",
    name: "",
    birthdate: "",
    province: "",
    city: "",
    school: "",
    dream_university: "",
    profileImage: "",
    email: "",
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      if (currentUser) {
        try {
          const userData = await getUserById(currentUser.uid);
          setProfileData({
            uid: currentUser.uid,
            name: userData.name,
            birthdate: userData.birthdate,
            province: userData.province,
            city: userData.city,
            email: userData.email,
            school: userData.school,
            dream_university: userData.dream_university,
            profileImage: userData.profileImage,
          });
        } catch (error) {
          console.error("Failed to fetch user data", error);
        }
      }
    };

    fetchProfileData();
  }, [currentUser]);

  const handleSave = (newProfileData) => {
    setProfileData(newProfileData);
    setIsModalVisible(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = "/signin";
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  return (
    <header className="relative flex flex-col items-center md:flex-row md:justify-center md:gap-4 py-4">
      <section className="flex w-full justify-end items-center md:absolute">
  <button
    className="bg-[#f5f5f5] px-3 py-2 rounded-lg flex items-center gap-1 hover:opacity-75"
    onClick={() => setIsModalVisible(true)}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-pencil"
    >
      <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z" />
      <path d="m15 5 4 4" />
    </svg>
    <span className="hidden md:inline">Edit Profil</span>
  </button>
</section>

      <section className="header-container py-5 flex flex-col justify-center items-center gap-4 mt-13 md:flex-col md:gap-8">
      <img
    src={
      currentUser?.profileImage !== "default"
        ? currentUser.profileImage
        : images.DefaultPP
    }
    alt="Profile"
    className="w-40 h-40 object-cover rounded-full"
  />
        <div className="flex flex-col gap-4 items-center text-center">
          <p className="text-2xl font-semibold text-center">
            {profileData.name}
          </p>
          <section className="flex flex-col gap-4 justify-center items-center">
            <div className="flex gap-4">
              <div className="flex gap-3 items-center">
                <RiGraduationCapFill color="#FF000B" className="text-lg" />
                <p className="text-sm">{profileData.school}</p>
              </div>
              <div className="flex gap-3 items-center">
                <MdLocationOn color="#FF000B" className="text-lg" />
                <p className="text-sm">{profileData.city}</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2 items-center justify-center">

              <div className="flex items-center justify-center gap-2">
  <button
    onClick={handleLogout}
    className="bg-red-500 text-white text-[8px] px-3 py-2 rounded-lg flex items-center gap-2 mt-4 text-base md:text-sm lg:text-base"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-log-out"
    >
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
      <polyline points="16 17 21 12 16 7" />
      <line x1="21" x2="9" y1="12" y2="12" />
    </svg>
    Logout
  </button>

  <a
    href="https://wa.me/6285183073757"
    target="_blank"
    rel="noopener noreferrer"
    className="inline-block"
  >
    <button className="bg-[#f5f5f5] text-gray-800 text-[20px] px-3 py-2 rounded-lg flex items-center gap-2 mt-4 text-base md:text-sm lg:text-base">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-circle-help"
      >
        <circle cx="12" cy="12" r="10" />
        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
        <path d="M12 17h.01" />
      </svg>
      <span className="md:inline">Help</span>
    </button>
  </a>
  </div>
  <a
    href="https://forms.gle/pWeRwttJHn6skRAb7"
    target="_blank"
    rel="noopener noreferrer"
    className="inline-block"
  >
    <button className="bg-[#f5f5f5] text-gray-800 px-3 py-2 rounded-lg flex items-center gap-2 mt-4 text-base md:text-sm lg:text-base w-full ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-list-checks"
      >
        <path d="m3 17 2 2 4-4" />
        <path d="m3 7 2 2 4-4" />
        <path d="M13 6h8" />
        <path d="M13 12h8" />
        <path d="M13 18h8" />
      </svg>
      <span >
        Konfirmasi Pembayaran
      </span>
    </button>
  </a>
</div>

          </section>
        </div>
      </section>
      

      {/* Modal Component */}
      <ModalProfil
        isVisible={isModalVisible}
        profileData={profileData}
        onClose={() => setIsModalVisible(false)}
        onSave={handleSave}
      />
    </header>
  );
};

export default Header;
