import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getCourseById, checkSubscription, getMaterialByCourse } from '../services/api';
import Navbar from '../components/courses/navbar';
import Footer from '../components/beranda/footer';
import OrderDetail from '../components/courseDetail/oderDetail';
import { images } from "../constants";
import { useAuth } from '../contexts/authContext';

const CourseDetail = () => {
  const [course, setCourse] = useState(null);
  const [expandedModule, setExpandedModule] = useState(false);
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // Untuk menyimpan status langganan
  const navigate = useNavigate();
  const { id } = useParams();
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const data = await getCourseById(id);
        setCourse(data);
    
        console.log('Course data:', data); // Log the course data
    
        if (currentUser) {
          console.log('Current user UID:', currentUser.uid); // Log current user's UID
    
          const subscriptionResult = await checkSubscription(currentUser.uid, id);
    
          console.log('Subscription result:', subscriptionResult); // Log the subscription result
    
          if (subscriptionResult) {
            if (subscriptionResult.subscribed === true) {
              setSubscriptionStatus('subscribed');
              console.log('Subscription status: subscribed'); // Log when subscribed
            } else if (subscriptionResult.subscribed === false) {
              setSubscriptionStatus('pending');
              console.log('Subscription status: pending'); // Log when pending
            } else {
              setSubscriptionStatus('notSubscribed');
              console.log('Subscription status: notSubscribed'); // Log when not subscribed
            }
          } else {
            setSubscriptionStatus('notSubscribed');
            console.log('Subscription status: notSubscribed (no result)'); // Log when no result
          }
        } else {
          setSubscriptionStatus('notSubscribed');
          console.log('Subscription status: notSubscribed (no currentUser)'); // Log when no currentUser
        }
      } catch (error) {
        console.error('Error fetching course details', error);
        setSubscriptionStatus('error');
      }
    };
    
    fetchCourse();
    }, [id, currentUser]);
    

  const toggleModule = () => {
    setExpandedModule(!expandedModule);
  };

  const handleBuyNow = () => {
    setShowOrderDetail(true);
  };

  const handleCloseOrderDetail = () => {
    setShowOrderDetail(false);
  };

  const handleStartLearning = async () => {
    try {
      const materials = await getMaterialByCourse(id);
      materials.sort((a, b) => a.order - b.order);
      const firstMaterial = materials[0];
      
      if (firstMaterial) {
        navigate(`/courses/${id}/materials/${firstMaterial.id}`, {
          state: { courseId: id, materialId: firstMaterial.id }
        });
      } else {
        console.error('No materials found');
      }
    } catch (error) {
      console.error('Error fetching materials', error);
    }
  };

  if (!course) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="sticky top-0 z-10 bg-white">
        <Navbar />
      </div>
      <div className="w-full flex flex-col gap-10">
        <div className="bg-f5f5f5 rounded-lg w-full p-9 flex flex-col md:flex-row md:h-64 gap-4">
          <div className="flex flex-col justify-between md:w-2/3">
            <img className="w-8" src={images.LogoIcon} alt="Logo" />
            <div className="font-semibold text-[30px] text-edured mt-4">
              {course.title}
            </div>
            <div className="font-light text-[12px]">
              {course.short_desc}
            </div>
            <div className="w-full flex gap-3 items-center mt-4">
              <div className="font-bold text-red-600 text-[14px]">Instruktur:</div>
              <div className='text-[12px]'>{course.sensei}</div>
            </div>
          </div>
          <div className="flex md:w-1/3 items-center justify-start md:justify-center">
            {subscriptionStatus === 'subscribed' && (
              <button 
                className="bg-edured py-2 px-4 text-white text-[16px] rounded-sm"
                onClick={handleStartLearning}
              >
                Belajar Sekarang
              </button>
            )}
            {subscriptionStatus === 'pending' && (
              <button 
                className="bg-gray-400 py-2 px-4 text-white text-[16px] rounded-sm "
                onClick={handleBuyNow}
              >
                Menunggu Validasi
              </button>
            )}
            {subscriptionStatus === 'notSubscribed' && (
              <button 
                className="bg-edured py-2 px-4 text-white text-[16px] rounded-sm"
                onClick={handleBuyNow}
              >
                Beli Course
              </button>
            )}
          </div>
        </div>

        <div className="w-full flex flex-col gap-4">
          <div className="w-full text-black font-semibold text-[16px]">
            Deskripsi
          </div>
          <div className="text-[14px">
            {course.description}
          </div>
        </div>

        <div className="w-full flex flex-col gap-4">
          <div className="w-full text-black font-semibold text-[16px]">
            Apa yang akan kamu pelajari
          </div>
          <div className="bg-white border border-gray-300 rounded-lg p-4 mb-2">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={toggleModule}
            >
              <div className="font-semibold">{course.title}</div>
              
              <div>
                <svg
                  className={`w-5 h-5 transition-transform ${
                    expandedModule ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </div>
            </div>
            {expandedModule && (
              <div className="mt-2">
                {course.points.map((point, index) => (
                  <div
                    key={index}
                    className="border-t border-gray-200 pt-2 mt-2"
                  >
                    {point}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />

      {showOrderDetail && currentUser && (
        <OrderDetail
          orderDetails={{
            productImage: "https://via.placeholder.com/30", 
            productName: course.title,
            price: course.price,
            priceNormal: course.price_normal,
            customerName: currentUser.name,
            customerEmail: currentUser.email,
            courseId: course.id,
            userId: currentUser.uid
          }}
          onClose={handleCloseOrderDetail}
        />
      )}
    </div>
  );
};

export default CourseDetail;
