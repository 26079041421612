import React, { useEffect, useState } from "react";
import FormProfil from "./formProfile";
import { getUserById } from "../../services/api"; // Pastikan path sesuai dengan lokasi file api.js
import { useAuth } from "../../contexts/authContext"; // Pastikan path sesuai dengan lokasi file authContext

const ModalProfil = ({ isVisible, profileData, onClose, onSave }) => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(profileData);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const fetchedUserData = await getUserById(currentUser.uid);
          setUserData({
            namaLengkap: fetchedUserData.name,
            tanggalLahir: fetchedUserData.birthdate,
            provinsiAsal: fetchedUserData.province,
            kotaAsal: fetchedUserData.city,
            namaSekolah: fetchedUserData.school,
            universitasImpian: fetchedUserData.dream_university,
            profileImage: fetchedUserData.profileImage,
          });
        } catch (error) {
          console.error("Failed to fetch user data", error);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  if (!isVisible) return null;

  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center z-10 overflow-y-auto" onClick={handleBackdropClick}>
      <div className="relative flex items-center justify-center w-full max-w-3xl rounded-lg shadow-md align-middle md:mt-10 md:m-auto m-4 overflow-y-auto">
        <div className="flex bg-white p-8 rounded-lg shadow-md relative w-full">
          <FormProfil profileData={userData} onSave={onSave} />
        </div>
      </div>
    </div>
  );
};

export default ModalProfil;
