import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import PersonalInformationForm from "../components/PersonalInformationForm";
import Header from '../components/authentication/header';
import Footer from '../components/beranda/footer';


const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState("");
    const [showPersonalInfoForm, setShowPersonalInfoForm] = useState(false);
    const navigate = useNavigate();

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            setError("");
            // Use Firebase Authentication to create user
           

            // Show personal info form after successful signup
            setShowPersonalInfoForm(true); 
        } catch (error) {
            setError(error.message); 
        }
    };

    return (
        <>
            <div className="sticky top-0 z-10 bg-white" >
            <Header />
            </div>
            <div className="w-full flex justify-center items-center mt-20">
                <div className="w-5/6 sm:w-1/2">
                    <h2 className="text-2xl font-bold mb-6 text-center">Sign Up</h2>
                    <p className="text-center mb-8">Selamat datang! Daftar untuk memulai belajar.</p>
                    {error && <p className="text-red-500 text-center">{error}</p>}
                    {!showPersonalInfoForm ? (
                        <form onSubmit={handleFormSubmit}>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-gray-700">Email</label>
                                <input
                                    type="text"
                                    id="email"
                                    className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                                    placeholder="kohai@gmail.com"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="mb-4 relative">
                                <label htmlFor="password" className="block text-gray-700">Password</label>
                                <div className="relative">
                                    <input
                                        type={passwordVisible ? "text" : "password"}
                                        className="w-full px-3 py-2 border rounded-md pr-10"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 px-3 py-2"
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                    >
                                        {passwordVisible ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-eye-off">
                                                <path d="M10.733 5.076a10.744 10.744 0 0 1 11.205 6.575 1 1 0 0 1 0 .696 10.747 10.747 0 0 1-1.444 2.49"/>
                                                <path d="M14.084 14.158a3 3 0 0 1-4.242-4.242"/>
                                                <path d="M17.479 17.499a10.75 10.75 0 0 1-15.417-5.151 1 1 0 0 1 0-.696 10.75 10.75 0 0 1 4.446-5.143"/>
                                                <path d="m2 2 20 20"/>
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-eye">
                                                <path d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0"/>
                                                <circle cx="12" cy="12" r="3"/>
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 mb-4 bg-red-500 text-white font-bold rounded-md hover:bg-red-600"
                            >
                                Sign Up
                            </button>
                        </form>
                    ) : (
                        <PersonalInformationForm email={email} password={password} />
                    )}
                    {!showPersonalInfoForm && (
                        <div className="text-center mt-4">
                            <p>
                                Sudah memiliki akun?{" "}
                                <Link
                                    to="/signin"
                                    className="text-red-500 hover:underline"
                                >
                                    Sign In Sekarang
                                </Link>
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default SignUp;
