import React from "react";
import Navbar from "../courses/navbar";
import Header from "./header";
import Footer from "../beranda/footer";

const MainLayout = ({ children }) => {
  return (
    <div>
      <div className="sticky top-0 z-10 bg-white"> 
      <Navbar />
      </div>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default MainLayout;