import React, { useState, useEffect } from 'react';
import { fetchPayments, createSubscription } from '../../services/api'; // Pastikan path sesuai
import { getAuth } from 'firebase/auth'; // Import getAuth dari Firebase
import { useNavigate } from 'react-router-dom';

const PaymentConfirmationModal = ({ isOpen, onClose, courseId }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth(); // Ambil instance Firebase Auth
  const currentUser = auth.currentUser; // Ambil currentUser dari Firebase Auth

  useEffect(() => {
    if (isOpen) {
      // Fetch payment methods when the modal is opened
      const loadPayments = async () => {
        try {
          const payments = await fetchPayments();
          setPaymentMethods(payments);
        } catch (error) {
          console.error('Error loading payments:', error);
        }
      };
      loadPayments();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedIndex(index);
    });
  };

  const handleConfirmPayment = async () => {
    if (!currentUser) {
      console.error('User is not authenticated');
      return;
    }

    setLoading(true);
    try {
      // Siapkan data untuk subscription
      const subscriptionData = {
        course_id: courseId,
        user_id: currentUser.uid
      };

      // Panggil API untuk membuat subscription
      await createSubscription(subscriptionData);

      // Arahkan pengguna ke URL setelah berhasil
      window.location.href = 'https://forms.gle/pWeRwttJHn6skRAb7';
    } catch (error) {
      console.error('Error confirming payment:', error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white p-6 shadow-lg w-full max-w-md max-h-[83vh] overflow-y-auto scrollbar-hidden">
        {/* Header */}
        <div className="flex justify-end">
          <button
            className="text-gray-600"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              strokeWidth="2"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="text-center">
          <div className="text-lg font-semibold mb-4">Metode Pembayaran</div>
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full">
              <div className="flex bg-gray-100 font-semibold">
                <div className="w-1/3 py-2 px-4 text-left">Platform</div>
                <div className="w-1/2 py-2 px-4 text-left">Nomor</div>
                <div className="w-1/4 py-2 px-4 text-center">Salin</div>
              </div>
              {paymentMethods.map((method, index) => (
                <div key={index} className="flex items-center border-t border-gray-200">
                  <div className="w-1/3 py-2 px-4 text-left">{method.platform}</div>
                  <input
                    type="text"
                    value={method.number}
                    readOnly
                    className="w-1/2 py-2 px-2 text-gray-800 bg-transparent border-none"
                  />
                  <button
                    onClick={() => handleCopy(method.number, index)}
                    className="ml-2 text-gray-500 h-10 flex items-center"
                  >
                    {copiedIndex === index ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-check"
                      >
                        <path d="M20 6 9 17l-5-5" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-copy"
                      >
                        <rect width="8" height="10" x="10" y="8" rx="2" ry="2" />
                        <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
                      </svg>
                    )}
                  </button>
                </div>
              ))}
            </div>
          </div>

          <span className="block text-xs text-gray-600 mt-4">
            Anda dapat mengakses formulir yang sama di halaman profil Anda. Pemesanan akan diproses maksimal 1 x 24 jam
          </span>
          <div className="mt-4 flex justify-center">
            <button
              className="bg-edured text-white py-2 px-4 hover:bg-red-800"
              onClick={handleConfirmPayment}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Konfirmasi Pembayaran'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentConfirmationModal;
