import React, { useState } from 'react';
import { images } from "../../constants/";
import { useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleSignInClick = () => {
    navigate('/signin', { state: { backgroundLocation: location } });
  };

  return (
    <section>
      <header className="header-container m-auto py-5 flex justify-between items-center">
        <div className="header-image-container h-9 w-30">
          <img className="h-full" src={images.Logo} alt="Logo" />
        </div>

        <div className="lg:hidden">
          <button onClick={toggleMenu} className="mobile-menu-button">
            {showMenu ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-x"
                style={{ transition: "transform 0.3s ease-in-out" }}
              >
                <path d="M18 6 6 18"/>
                <path d="m6 6 12 12"/>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-menu"
                style={{ transition: "transform 0.3s ease-in-out" }}
              >
                <line x1="4" x2="20" y1="12" y2="12"/>
                <line x1="4" x2="20" y1="6" y2="6"/>
                <line x1="4" x2="20" y1="18" y2="18"/>
              </svg>
            )}
          </button>
        </div>

        <div className="hidden lg:flex gap-x-8 items-center">
          <ul className="flex gap-x-8 justify-center items-center">
            <li><a href="#hero">tentang.</a></li>
            <li><a href="#introduction">sensei.</a></li>
            <li><a href="#people">people.</a></li>
          </ul>
          <div className="flex gap-x-1.5">
            <button className="bg-edured px-4 py-1.5 text-white" onClick={() => navigate('/signup')}>Sign Up</button>
            <button className="bg-white border border-edured px-4 py-1.5 text-edured" onClick={handleSignInClick}>Sign In</button>
          </div>
        </div>
      </header>

      <div className={`lg:hidden ${showMenu ? 'block' : 'hidden'}`}>
        <ul className="flex flex-col gap-y-4 py-4 px-4 border-t border-gray-200">
          <li><a href="#hero">tentang.</a></li>
          <li><a href="#introduction">sensei.</a></li>
          <li><a href="#people">people.</a></li>
        </ul>
        <div className="flex flex-col gap-y-4 py-4 px-4 border-t border-gray-200">
          <button className="bg-edured px-4 py-1.5 text-white" onClick={() => navigate('/signup')}>Sign Up</button>
          <button className="bg-white border border-edured px-4 py-1.5 text-edured" onClick={handleSignInClick}>Sign In</button>
        </div>
      </div>
    </section>
  );
};

export default Header;
