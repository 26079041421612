import Logo from "../assets/beranda/Logo.png";
import LogoIcon from "../assets/beranda/LogoIcon.png"
import Hero from "../assets/beranda/Hero.png"
import Character from "../assets/beranda/Character.png"
import People1 from "../assets/beranda/People1.png"
import People2 from "../assets/beranda/People2.png"
import People3 from "../assets/beranda/People3.png"
import People4 from "../assets/beranda/People4.png"
import People5 from "../assets/beranda/People5.png"
import People6 from "../assets/beranda/People6.png"
import EmptyState from "../assets/profile/amico.svg";
import Qr from "../assets/qr/frame.png";
import DefaultPP from "../assets/profile/default-pp.png"

const images = {
    Logo, LogoIcon, Hero, Character, People1, People2, People3, People4,People5,People6, EmptyState, Qr, DefaultPP
}
export default images;