import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../components/courses/sidebar';
import Navbar from '../components/courses/navbar';
import Footer from '../components/beranda/footer';
import { getMaterialById, getCourseById, getVideoById, getMaterialByCourse, getQuizByCourseId } from '../services/api';

// Ikon SVG Kustom
const MoveRightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-move-right">
    <path d="M18 8L22 12L18 16" />
    <path d="M2 12H22" />
  </svg>
);

const Module = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [material, setMaterial] = useState(null);
  const [course, setCourse] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [courseId, setCourseId] = useState(null);
  const [materials, setMaterials] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { materialId } = useParams();

  useEffect(() => {
    if (location.state && location.state.courseId) {
      setCourseId(location.state.courseId);
      console.log('courseId from location state:', location.state.courseId);
    } else {
      console.error("No courseId found in state");
    }
  }, [location.state]);

  useEffect(() => {
    const fetchCourseMaterialAndVideo = async () => {
      try {
        console.log('Fetching course, materials, and video for courseId:', courseId, 'and materialId:', materialId);
        const courseData = await getCourseById(courseId);
        setCourse(courseData);
        console.log('Fetched course data:', courseData);

        const materialsData = await getMaterialByCourse(courseId);
        setMaterials(materialsData);
        console.log('Fetched materials data:', materialsData);

        const currentMaterial = materialsData.find(mat => mat.id === materialId);
        setMaterial(currentMaterial);
        console.log('Current material:', currentMaterial);

        if (currentMaterial && currentMaterial.video_id) {
          const videoData = await getVideoById(currentMaterial.video_id);
          setVideoUrl(videoData.url);
          console.log('Fetched video URL:', videoData.url);
        }
      } catch (error) {
        console.error('Error fetching course, material, or video', error);
      }
    };

    if (courseId && materialId) {
      fetchCourseMaterialAndVideo();
    }
  }, [courseId, materialId]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleButtonClick = () => {
    navigate('/quizzes');
  };

  const handleNextMaterial = async () => {
    if (material) {
      const currentIndex = materials.findIndex(mat => mat.id === material.id);
      const nextMaterial = materials[currentIndex + 1];
  
      if (nextMaterial) {
        navigate(`/courses/${courseId}/materials/${nextMaterial.id}`, {
          state: { courseId: courseId }
        });
      } else {
        try {
          const quizData = await getQuizByCourseId(courseId);
          if (quizData && quizData[0].id) {
            navigate(`/courses/${courseId}/quizzes/${quizData[0].id}`, {
              state: { courseId: courseId }
            });
          } else {
            console.error('No quiz found for this course');
          }
        } catch (error) {
          console.error('Error fetching quiz', error);
        }
      }
    }
  };

  if (!material || !course || !videoUrl) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen">
      <div className="sticky top-0 z-10 bg-white">
        <Navbar />
      </div>
      <div className="flex justify-between items-center py-4 rounded-lg">
        <div className="text-sm">
          Courses / {course.title}
        </div>
        <div>
          <button className="bg-gray-200 text-gray-600 flex items-center px-6 py-1 rounded" onClick={handleNextMaterial}>
            <MoveRightIcon />
          </button>
        </div>
      </div>

      <div className="flex flex-col md:flex-row mt-4">
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} courseId={courseId} />

        <div className="flex-1 bg-white border border-solid border-d8d8d8 mt-4 md:mt-0 md:ml-4 rounded-lg p-6 transition-all duration-10000 ease-out lg:ml-4" style={{ padding: '24px 21px' }}>
          <div className="mb-4 relative overflow-hidden" style={{ paddingTop: "56.25%" }}>
            <iframe
              className="absolute top-0 left-0 w-full h-full"
              src={`https://www.youtube.com/embed/${videoUrl}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <div>
            <div className="text-md font-semibold mb-5">{material.title}</div>
          </div>

          <div className="flex items-center mb-5">
            <img
              className="w-12 h-12 object-cover rounded-full mr-4"
              src="https://firebasestorage.googleapis.com/v0/b/edusensei2024.appspot.com/o/default-pp-sensei.png?alt=media&token=e9446dfa-f05b-4d56-a56e-425c51c64264"
              alt="Profile"
            />
            <div>
              <div className="text-md font-normal">{course.sensei}</div>
            </div>
          </div>

          <div>
            <div className="text-md font-semibold mb-5">Deskripsi</div>
          </div>
          <span className="text-md">
            {material.description} 
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Module;
