import React from 'react'

const Footer = () => {
  return (
    <section className="m-auto mt-20 py-7 flex justify-between items-center">
        <div className="m-auto py-2 flex justify-center items-center border border-customgray w-full">
            <span className="text-edured text-sm md:text-md"> ©EduSensei 2024. All rights reserved. </span>
        </div>
     </section>
  )
}

export default Footer
