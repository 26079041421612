// ResetPassword.jsx
import React, { useState } from "react";
import Header from '../components/authentication/header';
import Footer from '../components/beranda/footer';
import { useAuth } from "../contexts/authContext";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const { resetPassword } = useAuth(); // Ambil fungsi resetPassword dari konteks
  const navigate = useNavigate();

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      setError("");
      await resetPassword(email); // Panggil fungsi resetPassword
      setMessage("Check your inbox for further instructions");
    } catch {
      setError("Failed to reset password");
    }
  };

  return (
    <>
      <div className="sticky top-0 z-10 bg-white" >
            <Header />
            </div>
      <div className="w-full flex justify-center items-center mt-20">
        <div className="w-5/6 sm:w-1/2">
          <h2 className="text-2xl font-bold mb-6 text-center">Reset Password</h2>
          <p className="text-center mb-8">Konnichiwa! Yuk reset password kamu!</p>
          {error && <p className="text-red-500 text-center">{error}</p>}
          {message && <p className="text-green-500 text-center">{message}</p>}
          <form onSubmit={handleFormSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                placeholder="kohai@gmail.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 mb-4 bg-red-500 text-white font-bold rounded-md hover:bg-red-600"
            >
              Send Reset Link
            </button>
          </form>
          <div className="text-center mt-4">
            <p>
              Kembali ke{" "}
              <a
                href="/signin"
                className="text-red-500 hover:underline"
              >
                Sign In
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
