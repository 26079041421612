import React, { useState } from 'react';
import { images } from "../../constants/";
import { useAuth } from '../../contexts/authContext'; // Adjust the path based on your project structure
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { logout, currentUser } = useAuth(); // Get the logout function and currentUser from context
  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = async () => {
    try {
      await logout(); // Call the logout function
      // Redirect user to login page or any other page after logout
      window.location.href = '/login';
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  const handleClick = () => {
    navigate('/profile');
  };

  return (
    <section>
      <header className="header-container m-auto py-5 flex justify-between items-center px-4 md:px-8">
        <div className="header-image-container h-9 w-30">
          <img className="h-full" src={images.LogoIcon} alt="Logo Icon" />
        </div>

        {/* Menu Hamburger untuk Mobile */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="mobile-menu-button">
            {showMenu ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-x"
                style={{ transition: "transform 0.3s ease-in-out" }}
              >
                <path d="M18 6 6 18"/>
                <path d="m6 6 12 12"/>
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-menu"
                style={{ transition: "transform 0.3s ease-in-out" }}
              >
                <line x1="4" x2="20" y1="12" y2="12"/>
                <line x1="4" x2="20" y1="6" y2="6"/>
                <line x1="4" x2="20" y1="18" y2="18"/>
              </svg>
            )}
          </button>
        </div>

        {/* Menu Navigasi untuk Desktop */}
        <div className="hidden md:flex gap-x-8 items-center">
          <ul className="flex gap-x-8 justify-center items-center">
            <li><a href="/courses">courses.</a></li>
            <li><a href="/paket-soal">paket-soal.</a></li>
            <li><a href="/profile">profile.</a></li>
          </ul>
        </div>

        <div className="hidden md:block header-image-container h-9 w-9">
          <img 
           src={currentUser?.profileImage !== "default" ? currentUser.profileImage : images.DefaultPP} 
            alt="Profile" 
            className="w-full h-full object-cover rounded-full cursor-pointer"
            onClick={handleClick}
          />
        </div>
      </header>

      {/* Menu Navigasi untuk Mobile */}
      <div className={`md:hidden ${showMenu ? 'block' : 'hidden'}`}>
        <ul className="flex flex-col gap-y-4 py-4 px-4 border-t border-gray-200">
          <li><a href="/courses">courses.</a></li>
          <li><a href="/paket-soal">paket-soal.</a></li>
          <li><a href="/profile">profile.</a></li>
        </ul>
        <div className="flex items-center gap-5 py-4 px-4 border-t border-gray-200">
          <div className="header-image-container h-9 w-9">
            <img
               src={currentUser?.profileImage !== "default" ? currentUser.profileImage : images.DefaultPP} 
              alt="Profile"
              className="w-full h-full object-cover rounded-full cursor-pointer"
              onClick={handleClick}
            />
          </div>
          <span className="text-sm font-medium">{currentUser?.name || "Nama Pengguna"}</span>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
