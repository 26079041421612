import './App.css';
import CourseDetail from './pages/courseDetail';
import Home from './pages/home';
import Courses from './pages/courses';
import PaketSoal from './pages/paketSoal';
import TemplateContent from './pages/templateContent'; // Import TemplateContent
import SignIn from './pages/signin';
import SignUp from './pages/signup';
import Profile from "./pages/profile";
import ResetPassword from './pages/resetPassword';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from './protectedRoute.jsx';
import { AuthProvider } from './contexts/authContext';
import Module from './pages/module';
import Quizes from './pages/quizes'

function App() {
  return (
    <AuthProvider>
      <div className="App px-8 md:px-18 lg:px-32 font-sans">
        <Router>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/profile" element={<ProtectedRoute element={Profile} />} />
            <Route path="/courses" element={<ProtectedRoute element={Courses} />} />
            <Route path="/paket-soal" element={<ProtectedRoute element={PaketSoal} />} />
            <Route path="/courses/:id" element={<ProtectedRoute element={CourseDetail} />} />
            <Route path="/courses/:id/materials/:materialId" element={<ProtectedRoute element={Module} />} />
            <Route path="/courses/:id/quizzes/:quizId" element={<ProtectedRoute  element={Quizes}  />} />
          </Routes>
        </Router>
      </div>
    </AuthProvider>
  );
}

export default App;
