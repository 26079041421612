import React from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const CardList = ({ data }) => {
  const navigate = useNavigate(); // Inisialisasi useNavigate

  const handleTitleClick = (id) => {
    navigate(`/courses/${id}`); // Navigasi ke halaman detail kursus
  };

  return (
    <section className="grid grid-cols-1 gap-4">
      {data.map((item) => (
        <div key={item.id} className="flex flex-col border border-[#D8D8D8] rounded-md h-fit w-full p-4 gap-4">
          <section className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <h1
                className="text-lg font-semibold cursor-pointer" // Tambahkan cursor-pointer untuk menunjukkan klik
                onClick={() => handleTitleClick(item.id)} // Tambahkan handler klik
              >
                {item.title}
              </h1>
              <p className="text-[#8D8D8D] line-clamp-1">{item.sensei}</p>
            </div>
            <div className="flex justify-end">
              <div className="border border-[#D8D8D8] rounded-full text-[#8D8D8D] w-fit h-fit px-3">
                <p>{item.category}</p>
              </div>
            </div>
          </section>
        </div>
      ))}
    </section>
  );
};

export default CardList;
