import React from "react";
import { useAuth } from "../contexts/authContext"; // Pastikan path sesuai dengan lokasi file authContext
import MainLayout from "../components/profile/mainLayout";
import WrapContent from "../components/profile/wrapContent";

const Profile = () => {
  const { currentUser } = useAuth();

  return (
    <MainLayout>
      <div className="flex flex-col">
        {/* Pastikan currentUser tersedia sebelum mengoper ke WrapContent */}
        {currentUser ? (
          <WrapContent currentUserId={currentUser.uid} />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </MainLayout>
  );
};

export default Profile;
