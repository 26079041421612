import React, { useState, useEffect } from "react";
import { getPaidSubscriptionsByUserId, getCourseById, getQuizByCourseId } from "../../services/api";
import CardGrid from "./cardGrid";
import CardList from "./cardList";
import EmptyState from "./emptyState";
import { MdOutlineGridView } from "react-icons/md";
import { IoListSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

export const tabs = ["course", "kuis", "Paket Soal"];
export const dataMap = {
  course: [],
  kuis: [],
  paketSoal: [],
};

const WrapContent = ({ currentUserId }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [viewMode, setViewMode] = useState("grid");
  const [courseData, setCourseData] = useState([]);
  const [quizData, setQuizData] = useState([]); // State untuk data kuis
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching paid subscriptions...');
        const subscriptions = await getPaidSubscriptionsByUserId(currentUserId);
        console.log('Subscriptions:', subscriptions);

        // Fetch course data
        console.log('Fetching courses...');
        const courses = await Promise.all(
          subscriptions.map(async (subscription) => {
            const course = await getCourseById(subscription.course_id);
            console.log('Course fetched:', course);
            return course;
          })
        );
        setCourseData(courses);

        // Fetch quiz data
        console.log('Fetching quizzes...');
        const quizzes = await Promise.all(
          subscriptions.map(async (subscription) => {
            const quizzes = await getCourseById(subscription.course_id);
            console.log('Quizzes fetched:', quizzes);
            return quizzes;
          })
        );
        setQuizData(quizzes.flat());

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUserId]);

  useEffect(() => {
    // When the active tab changes, re-fetch the data for that tab if needed
    if (activeTab === "course") {
      setCourseData(prevData => [...prevData]);
    } else if (activeTab === "kuis") {
      setQuizData(prevData => [...prevData]);
    }
  }, [activeTab]);

  const handleTitleClick = async (courseId) => {
    console.log('Handle title click:', courseId);

    if (activeTab === "kuis") {
      try {
        console.log('Filtering quizzes for course:', courseId);
        const quizzes = quizData.filter(quiz => quiz.course_id === courseId);

        if (quizzes.length > 0) {
          const firstQuizId = quizzes[0].id; // Ambil ID quiz pertama
          navigate(`/courses/${courseId}/quizzes/${firstQuizId}`);
        }
      } catch (error) {
        console.error('Error filtering quizzes', error);
      }
    } else {
      navigate(`/courses/${courseId}`);
    }
  };

  const emptyMessages = {
    course: "Kamu belum punya course nih",
    kuis: "Kamu belum mengerjakan kuis nih",
    paketSoal: "Kamu belum punya paket soal nih",
  };

  const emptySubMessages = {
    course: "Beli sekarang yuk!",
    kuis: "",
    paketSoal: "Beli sekarang yuk!",
  };

  const getTabKey = (tabLabel) => {
    const tabKeys = {
      course: "course",
      kuis: "kuis",
      "Paket Soal": "paketSoal",
    };
    return tabKeys[tabLabel];
  };

  const tabKey = getTabKey(activeTab);
  const data = tabKey === "course" ? courseData : tabKey === "kuis" ? quizData : dataMap[tabKey];
  const isEmpty = data.length === 0;

  const renderContent = (data, emptyMessage, emptySubMessage) => {
    console.log('Rendering content for tab:', tabKey);
    console.log('Data:', data);
  
    if (loading) {
      return <div>Loading...</div>;
    }
    if (isEmpty) {
      return <EmptyState message={emptyMessage} subMessage={emptySubMessage} />;
    }
    return viewMode === "grid" ? (
      <CardGrid
        courses={data}
        onTitleClick={handleTitleClick}
        activeTab={activeTab} // Oper activeTab ke CardGrid
      />
    ) : (
      <CardList data={data} />
    );
  };
  
  return (
    <div>
      <div>
        <section>
          <div className="flex">
            {tabs.map((tab) => (
              <div key={tab} className={`border border-[#f5f5f5] px-5 py-1 w-fit rounded-t-[4px] cursor-pointer ${activeTab === tab ? "bg-[#f5f5f5]" : ""}`} onClick={() => setActiveTab(tab)}>
                <p className="capitalize">{tab}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="border border-[#D8D8D8] p-4">
          {!isEmpty && (
            <div className="flex justify-end mb-4">
              <section className="w-fit flex gap-3">
                <div className={`flex items-center gap-2 border border-[#f5f5f5] px-4 py-2 text-sm cursor-pointer ${viewMode === "grid" ? "bg-[#f5f5f5]" : ""}`} onClick={() => setViewMode("grid")}>
                  <MdOutlineGridView />
                  <p>Grid</p>
                </div>
                <div className={`flex items-center gap-2 border border-[#f5f5f5] px-4 py-2 text-sm cursor-pointer ${viewMode === "list" ? "bg-[#f5f5f5]" : ""}`} onClick={() => setViewMode("list")}>
                  <IoListSharp />
                  <p>Daftar</p>
                </div>
              </section>
            </div>
          )}
          {renderContent(data, emptyMessages[tabKey], emptySubMessages[tabKey])}
        </section>
      </div>
    </div>
  );
};

export default WrapContent;
