import React, { useContext, useState, useEffect, createContext } from "react";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const signup = async (email, password) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            setCurrentUser(userCredential.user);
            return userCredential.user;
        } catch (error) {
            // Tangani kesalahan spesifik
            if (error.code === 'auth/email-already-in-use') {
                throw new Error('Email sudah digunakan. Silakan masuk menggunakan email ini atau daftar menggunakan email lain.');
            } else if (error.code === 'auth/invalid-email') {
                throw new Error('Email tidak valid.');
            } else if (error.code === 'auth/weak-password') {
                throw new Error('Password harus memiliki minimal 6 karakter.');
            } else {
                throw new Error('Gagal mendaftar. Silakan coba lagi.');
            }
        }
    };

    const login = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Load additional user data from Firestore
            const userDoc = await getDoc(doc(db, "users", user.uid));
            const userData = userDoc.data();
            
            setCurrentUser({ ...user, ...userData });
        } catch (error) {
            // Tangani kesalahan spesifik
            if (error.code === 'auth/user-not-found') {
                throw new Error('Akun tidak ditemukan. Silakan periksa email Anda.');
            } else if (error.code === 'auth/wrong-password') {
                throw new Error('Password salah. Silakan coba lagi.');
            } else if (error.code === 'auth/invalid-email') {
                throw new Error('Email tidak valid.');
            } else {
                throw new Error('Gagal login. Silakan coba lagi. Pastikan email dan password sudah benar');
            }
        }
    };

    const logout = () => {
        return signOut(auth);
    };

    const resetPassword = (email) => {
        return sendPasswordResetEmail(auth, email);
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userDoc = await getDoc(doc(db, "users", user.uid));
                const userData = userDoc.data();
                setCurrentUser({ ...user, ...userData });
            } else {
                setCurrentUser(null);
            }
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        signup,
        login,
        logout,
        resetPassword
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
