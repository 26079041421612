import React, { useState } from 'react';
import PaymentConfirmationModal from './PaymentConfirmationModal';

const OrderDetail = ({ orderDetails, onClose }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  // Menambahkan state untuk course_id
  const [courseId, setCourseId] = useState(orderDetails.courseId); // Asumsi courseId ada di orderDetails

  const handleContinuePayment = () => {
    setShowModal(true);
  };
  const formatCurrency = (amount) => {
    return Math.floor(amount).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' }).replace('Rp ', '');
  };
  return (
    <>
      <div className="fixed bottom-0 left-0 w-full bg-white shadow-lg z-50 p-2 max-h-screen overflow-auto">
        {/* Top Section: Close Icon */}
        <div className="flex justify-end">
          <button
            className="text-gray-600"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              strokeWidth="2"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        {/* Bottom Section */}
        <div className="flex flex-col sm:flex-row">
          {/* Left Section */}
          <div className="w-full sm:w-2/3 p-2 ml-2 flex flex-col justify-center items-start">
  <div className="text-lg font-bold mb-2">{orderDetails.productName}</div>
  <div className="flex items-center space-x-2">
    <span className="text-lg font-semibold text-red-600">{formatCurrency(orderDetails.price)}</span>
    {orderDetails.priceNormal && (
      <span className="text-lg font-semibold text-gray-800 line-through">
        {formatCurrency(orderDetails.priceNormal)}
      </span>
    )}
  </div>
  <div>{orderDetails.customerName} - {orderDetails.customerEmail}</div>
</div>
          {/* Right Section */}
          <div className="w-full sm:w-1/3 p-2 flex items-center justify-center">
            <button
              className="bg-edured text-white py-2 px-4"
              onClick={handleContinuePayment}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Lanjutkan Pembayaran'}
            </button>
          </div>
        </div>
      </div>

      {/* Payment Confirmation Modal */}
      <PaymentConfirmationModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        courseId={courseId} // Kirimkan courseId sebagai props
      />
    </>
  );
};

export default OrderDetail;
