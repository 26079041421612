import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCourses, getCourseByName, getAllGenres, getCourseByGenre } from '../services/api'; // Adjust the path to where your api.js file is located
import Navbar from '../components/courses/navbar';
import Footer from '../components/beranda/footer';

// Cards Component
const Cards = ({ courses }) => {
  const navigate = useNavigate();

  const handleCourseClick = (id) => {
    navigate(`/courses/${id}`);
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 gap-6">
      {courses.map((course) => (
        <div key={course.id} className="bg-white rounded-lg overflow-hidden shadow-sm h-70 p-4 border border-gray-300" onClick={() => handleCourseClick(course.id)}>
          <div>
            <img src={course.image} alt={course.title} className="w-full h-1/2 object-cover rounded-sm" />
            <div>
              <h3 className="text-[16px] font-semibold cursor-pointer mt-2" onClick={() => handleCourseClick(course.id)}>
                {course.title}
              </h3>
              <p className="text-[10px] sm:text-xs md:text-sm text-gray-600 mb-2">{course.sensei}</p>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="border border-gray-500 text-gray-500 text-[8px] sm:text-[10px] md:text-xs px-2 py-1 sm:px-3 sm:py-1 rounded-full">
              {course.category}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

// Category Component
const Category = ({ onGenreSelect }) => {
  const [genres, setGenres] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState(null);

  useEffect(() => {
    const fetchGenres = async () => {
      try {
        const data = await getAllGenres();
        setGenres(data);
      } catch (error) {
        console.error('Error fetching genres:', error);
      }
    };

    fetchGenres();
  }, []);

  const handleGenreClick = async (genre) => {
    if (selectedGenre === genre) {
      // If the same genre is clicked again, reset the filter
      setSelectedGenre(null);
      onGenreSelect(null);
    } else {
      setSelectedGenre(genre);
      onGenreSelect(genre);
    }
  };

  return (
    <div className="overflow-x-auto py-5" style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
      <div className="flex gap-4">
        {genres.map((genre) => (
          <div
            key={genre}
            className={`min-w-[150px] p-[4px] text-black text-[12px] border rounded flex items-center justify-center cursor-pointer transition-transform transform hover:scale-105 ${
              selectedGenre === genre ? 'bg-red-600 text-white border-red-600' : 'border-gray-300'
            }`}
            onClick={() => handleGenreClick(genre)}
          >
            {genre}
          </div>
        ))}
      </div>
    </div>
  );
};

// Search Component
const Search = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  return (
    <div className="bg-red-600 rounded-md sm:rounded-lg w-full h-64 p-6 flex justify-center items-center flex-col">
      <div className="flex justify-center items-center flex-col w-full">
        <h1 className="text-white font-medium text-[20px] sm:text-[23px] md:text-[25px] mb-4">EduSensei Course</h1>
        <h2 className="text-white text-center text-[10px] sm:text-[12px] md:text-[15px] mb-4">
          Pilih course sesuai kebutuhanmu, belajar bareng Sensei Favoritmu, dan tingkatkan pemahamanmu
        </h2>
        <div className="flex items-center w-5/6 sm:w-3/4 bg-white rounded-md sm:rounded-lg px-2 py-1 sm:px-3 sm:py-3/2 md:px-4 md:py-2">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full bg-transparent outline-none text-base md:text-lg placeholder:text-[12px] md:placeholder:text-base"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="ml-2 h-5 w-5 text-red-600 md:h-6 md:w-6 cursor-pointer"
            onClick={handleSearch}
          >
            <circle cx="11" cy="11" r="8" />
            <path d="m21 21-4.3-4.3" />
          </svg>
        </div>
      </div>
    </div>
  );
};

// PaketsoalPage Component
const PaketsoalPage = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGenre, setSelectedGenre] = useState(null);

  const fetchCourses = async () => {
    try {
      const data = selectedGenre
        ? await getCourseByGenre(selectedGenre)
        : searchTerm
        ? await getCourseByName(searchTerm)
        : await getCourses();
      setCourses(data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, [searchTerm, selectedGenre]);

  const handleGenreSelect = (genre) => {
    setSelectedGenre(genre);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div className="sticky top-0 z-10 bg-white">
        <Navbar />
      </div>
      <Search onSearch={setSearchTerm} />
      <Category onGenreSelect={handleGenreSelect} />
      <Cards courses={courses} />
      <Footer />
    </div>
  );
};

export default PaketsoalPage;
