import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/authContext"; // Import useAuth
import { doc, setDoc } from "firebase/firestore"; // Import Firestore methods
import { db, auth } from "../firebase"; // Import Firestore instance

const PersonalInformationForm = ({ email, password }) => {
  const [formData, setFormData] = useState({
    name: '',
    birthdate: '',
    province: '',
    city: '',
    school: '',
    dream_university: '',
    profileImage: 'default',
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { signup } = useAuth(); // Get signup function from AuthContext

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { name, birthdate, province, city, school, dream_university, profileImage } = formData;
      // Create Firebase user
      await signup(email, password);

      // Save user data to Firestore
      const uid = auth.currentUser.uid;
      await setDoc(doc(db, "users", uid), {
        name,
        birthdate,
        province,
        city,
        school,
        dream_university,
        profileImage,
        email
      });

      console.log('User document created successfully');
      navigate('/courses');
    } catch (error) {
      setError(error.message); // Show error from AuthContext
    }
  };

  // Check if all fields are filled
  const isFormValid = Object.values(formData).every(value => value.trim() !== '');

  return (
    <div className="max-w-xl max-h-full my-50">
      
      <h2 className="text-xl font-bold mb-4">Personal Information</h2>
      <p className="mb-6">
        Sebelum mulai belajar, kamu boleh isi informasi berikut ya!
      </p>
      
      
      

      <form onSubmit={handleSubmit}>
        {/* Form fields */}
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700">Nama</label>
          <input
            type="text"
            id="name"
            name="name"
            className="w-full px-3 py-2 border rounded-md"
            required
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="birthdate" className="block text-gray-700">Tanggal Lahir</label>
          <input
            type="date"
            id="birthdate"
            name="birthdate"
            className="w-full px-3 py-2 border rounded-md"
            required
            value={formData.birthdate}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="province" className="block text-gray-700">Provinsi</label>
          <input
            type="text"
            id="province"
            name="province"
            className="w-full px-3 py-2 border rounded-md"
            required
            value={formData.province}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="city" className="block text-gray-700">Kota/Kabupaten</label>
          <input
            type="text"
            id="city"
            name="city"
            className="w-full px-3 py-2 border rounded-md"
            required
            value={formData.city}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="school" className="block text-gray-700">Sekolah</label>
          <input
            type="text"
            id="school"
            name="school"
            className="w-full px-3 py-2 border rounded-md"
            required
            value={formData.school}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="dream_university" className="block text-gray-700">Universitas Impian</label>
          <input
            type="text"
            id="dream_university"
            name="dream_university"
            className="w-full px-3 py-2 border rounded-md"
            required
            value={formData.dream_university}
            onChange={handleChange}
          />
        </div>
        {error && <p className="text-red-500 text-center m-10">{error}</p>}
      
        <button
          type="submit"
          className={`w-full py-2 bg-red-500 text-white font-bold rounded-md hover:bg-red-600 ${!isFormValid ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={!isFormValid}
        >
          Submit
        </button>
        
      </form>
    </div>
  );
};

export default PersonalInformationForm;
